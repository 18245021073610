// 该文件用于创建vuex核心store
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
// 用于相应组件中的动作
const actions = {
    increament(context, b) {

    }
};
// 用于操作数据处理业务
const mutations = {
    updateSideRight(state, val) {
        state.sideRight = val
    },
};
// 用于存储数据
const state = {
    // 存放左侧右侧显示的
    sideRight: false,
};
export default new Vuex.Store({ actions, mutations, state })
// export default new Vuex.Store({actions,mutations,state,modules: {}})
import Vue from 'vue'
import App from './App.vue'
import "../src/assets/css/clear.css"
import { Carousel, CarouselItem } from 'element-ui';
import store from './store/index'
import "animate.css";
import Carousel3d from 'vue-carousel-3d';
import router from '@/router/index.js'


Vue.directive('lazy-background', {
  bind(el, binding) {
    const observer = new IntersectionObserver(entries => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        el.style.background = `url(${binding.value}) no-repeat center`;
        el.style.backgroundSize = 'cover';
        observer.disconnect();
      }
    });

    observer.observe(el);
  }
});


// main.js
Vue.directive('lazy-img', {

  bind(el, binding) {
    const options = {
      rootMargin: '0px',
      threshold: 0
    };

    const io = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {

        if (entry.isIntersecting) {
          const img = new Image();
          img.src = binding.value;
          img.onload = () => {
            el.src = img.src;
            observer.unobserve(el);
          };
        } else {
          el.removeAttribute('src');
        }
      });
    }, options);

    io.observe(el);
  }
});

// Lazy Poster Directive
Vue.directive('lazy-poster', {
  bind(el, binding) {
    const options = {
      rootMargin: '0px',
      threshold: 0
    };

    const io = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          el.poster = binding.value;
          observer.unobserve(el);
        }
      });
    }, options);

    io.observe(el);
  }
});

Vue.config.productionTip = false
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Carousel3d);

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')

<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import "animate.css";
export default {
  name: "App",
  components: {},
  created() {
    if (
      navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      window.location.href = "https://m.yituedu.cn/";
    } else {
      //window.location.href = "http://122.112.190.76:8991/"
    }
  },
  mounted() {
    this.notAllow();
  },
  methods: {
    notAllow() {
      document.addEventListener(
        "keydown",
        function (event) {
          if (
            (event.ctrlKey === true || event.metaKey === true) &&
            (event.which === 61 ||
              event.which === 107 ||
              event.which === 173 ||
              event.which === 109 ||
              event.which === 187 ||
              event.which === 189)
          ) {
            event.preventDefault();
          }
        },
        false
      );

      // Chrome IE 360
      window.addEventListener(
        "mousewheel",
        function (event) {
          if (event.ctrlKey === true || event.metaKey) {
            event.preventDefault();
          }
        },
        {
          passive: false,
        }
      );

      // firefox
      window.addEventListener(
        "DOMMouseScroll",
        function (event) {
          if (event.ctrlKey === true || event.metaKey) {
            event.preventDefault();
          }
        },
        {
          passive: false,
        }
      );
    },
  },
};
</script>

<style>
  .carousel-3d-slide {
    background:none;
    border: none;
  }

</style>

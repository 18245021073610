
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)



const router = new VueRouter({
    //tips:不想要 #（锚点）就添加下面代码
    mode: 'history',
    //4.配置路由的path和组件
    routes: [
        {
            path: '/',
            name: 'home',
            component: resolve => require(['@/components/page/home/index'], resolve)
        },
        {
            path: '/piano',
            name: 'piano',
            component: resolve => require(['@/components/page/piano/piano'], resolve)
        },
        {
            path: '/Expert',
            name: 'Expert',
            component: resolve => require(['@/components/page/Expert/ExpertInterviews'], resolve)
        },
        {
            path: '/Dance',
            name: 'Dance',
            component: resolve => require(['@/components/page/Dance/DanceVidew'], resolve)
        },
        {
            path: '/Painting',
            name: 'Painting',
            component: resolve => require(['@/components/page/Painting/index'], resolve)
        },
       
    ]
})
router.beforeEach((to, from, next) => {
    // 每次路由切换前将页面滚动至顶部
    window.scrollTo(0, 0);
    next();
});

export default router
